import React, { Component, forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from "axios";
import { ListItemAvatar, Typography, Table, TableHead, withStyles, createStyles, TableCell, Theme, TableRow, TableBody } from "@material-ui/core";
import PrivateComponent from "../Component2/PrivateComponent";
import WS from "../../Config/WS";
import Tabela from "../Tabela/Tabela";
import { AppState, IClienteLiberacao } from "../../reducer/ReducerTypes";
import { connect } from "react-redux";
import { SetClienteLiberacao } from "../../reducer/ActionsCreator";
import { Store } from "../../reducer/Reducer";
import Component2 from "../Component2/Component2";
import NocRequest from "../../utils/NocRequest";
import { IControllerResult } from "../../controllers/Types";
import Formatter from "../../utils/Formatters";
import { withRouter } from "react-router-dom";
import Tabela2 from "../Tabela/Tabela2";
import { INocatualizador } from "../../persistence/interfaces/INocatualizador";
import { INocatualizador_historico } from "../../persistence/interfaces/INocatualizador_historico";
import Utils from "../../utils/Utils";

const tableIcons: any = {
    Add: forwardRef((props, ref:any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref:any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref:any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref:any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref:any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref:any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref:any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref:any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref:any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref:any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref:any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref:any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref:any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref:any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref:any) => <ViewColumn {...props} ref={ref} />),
    AddVersao: forwardRef((props, ref:any) => <AddCircleIcon {...props} ref={ref} />),
};

interface IProps {
    appstate?: AppState;
    linhaproduto: string;
    SetClienteLiberacao: (clienteliberacao: IClienteLiberacao) => void;
}

interface IState {

}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

class CertificadoSite extends PrivateComponent<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.consulta = this.consulta.bind(this);
        this.removerSite = this.removerSite.bind(this);
    }
    componentDidMountPrivate() {

    }
    rowClick(event: any, rowData: any) {
        //RowClick(event, rowData, this.props.doError);
    }
    consulta(query: any) {
        const expr = (field: string, type: string, formmat: string) => {
            let s = field;
            if (type == "date")
                s = `to_char(${field}, '${formmat}')`;
            return s;
        }
        //console.log(query)
        let order = (query?.orderBy?.field || 'id') + ' ' + (query?.orderDirection || 'desc');
        let search: string = query?.search || '';
        let field: string = query?.field || '';
        let type: string = query?.fieldtype || '';
        let formmat: string = query?.fieldformmat || '';
        let where: string = "";
        where = (field == "" ? `
            where  upper(url::text) like upper('%${search.toUpperCase()}%')
        ` : `
            where  upper(url::text) like upper('%${search.toUpperCase()}%')
        `);

        return new Promise<any>(async(resolve, reject) => {
            let result: any = await axios.post(WS.URL_NOC + "entity/sqlpage", {
                sql: `
                    select * from (
                        select 
                            *
                        from certificados_site c
                    ) as con1
                    ${where}
                    order by ${order}               
                `,
                page: query.page,
                pageSize: query.pageSize,
                search: query.search,
                orderBy: "" 
            })
            // console.log(result)
    
            resolve({
                data: result?.data?.data,
                page: result?.data?.page-1,
                totalCount: result?.data?.total
            });
        })   
    }
    removerSite(url: string) {
        //console.log(rowData, `${rowData.licencaativa}`.toUpperCase());
        this.showConfirm("Confirma a remoção desse site da lista ?", async () => {
            await axios.delete(WS.URL_NOC + "certificadosite/" + url);
            this.addRefreshCount();
            this.doRefresh();
        });
    }
    renderPrivate() {
        return (
            <div style={{ maxWidth: "100%", width: '100%' }}>
                <Tabela2
                    // icons={tableIcons}
                    actions={[
                        {
                            icon: <AddCircleIcon/>,
                            isFreeAction: true,
                            //tooltip: "Adicionar uma nova versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                this.redirectTo("/certificado-site/new");
                            }
                        },
                        {
                            icon: <DeleteOutline/>,
                            //isFreeAction: false,
                            //tooltip: "Remover versão",
                            onClick: (data: any) => {
                                //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                                this.removerSite(data.url);
                            }
                        },
                        // {
                        //     icon: <Edit />,
                        //     //isFreeAction: false,
                        //     //tooltip: "Remover versão",
                        //     onClick: (data: any) => {
                        //         //(this.props.SetLinhaProduto as)(this.props.linhaproduto);
                        //         this.redirectTo(`/certificados-site/edit/${data.url}`);
                        //     }
                        // },
                    ]}
                    
                    columns={[
                        { title: "URL", field: "url" },
                        { title: "Última Atualização", field: "horario", render: Formatter.date("horario", "d/m/Y h:n") },
                        { title: "valido_de", field: "valido_de", render: Formatter.date("valido_de", "d/m/Y h:n") },
                        { title: "valido_ate", field: "valido_ate", render: Formatter.date("valido_ate", "d/m/Y h:n") },
                    ]}
                    fieldsSelect={[
                        "url"
                    ]}
                    data={this.consulta}
                    orderByField="url"
                    orderByDirection="asc"
                    // options={{
                    //     searchFieldAlignment: 'right',
                    //     minBodyHeight: 'calc(100vh - 200px)',
                    //     maxBodyHeight: 'calc(100vh - 200px)',
                    //     pageSize: 30,
                    //     pageSizeOptions: [9, 20, 30, 50, 100],
                    //     search: true,
                    // }}
                    // search2={true}
                    search={true}
                    pageSize={30}
                    // onSearchChange={(txt: string) => { console.log(txt); }}
                    // onQueryChange={() => { console.log('query changed') }}

                    title="Certificados dos Sites"
                    subtitle="Verifica a validade dos certificados dos sites"
                    actionBarFreeWidth={2}
                    actionsColumns={2}
                    actionColumnWidth={25}
                    // onRowClick={this.rowClick/*RowClick*/}
                    // localization={{
                    //     header: {
                    //         actions: ""
                    //     }
                    // }}
                    timeoutDetail={5000}     
                    refreshCount={this.state.refreshCount}
                    
                />
                {this.baseRender()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {appstate: state};
}
const mapDisptachToProps = (dispatch: any, ownProps: any) => {
    return {
        ...ownProps,
        SetClienteLiberacao: (clienteliberacao: IClienteLiberacao) => dispatch(SetClienteLiberacao(clienteliberacao)),
    };
}

export default connect(mapStateToProps, mapDisptachToProps)(CertificadoSite);