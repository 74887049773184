/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard";
import UserProfile from "./views/UserProfile/UserProfile";
import TableList from "./views/TableList/TableList";
import Typography from "./views/Typography/Typography";
import Icons from "./views/Icons/Icons";
import Maps from "./views/Maps/Maps";
import NotificationsPage from "./views/Notifications/Notifications";
import UpgradeToPro from "./views/UpgradeToPro/UpgradeToPro";
// core components/views for RTL layout
import RTLPage from "./views/RTLPage/RTLPage";
import Clientes from "../components/Clientes/Clientes";
import Main from "../components/Main/Main";
import Empresas from "../components/Empresas/Empresas";
import Login from "../components/Login/Login";
import EventNoteIcon from '@material-ui/icons/EventNote';
import LabelIcon from '@material-ui/icons/Label';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import SettingsIcon from '@material-ui/icons/Settings';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BackupIcon from '@material-ui/icons/Backup';
import Lojas from "../components/Lojas/Lojas";
import CVersao, { VersaoLinha1, VersaoLinha2 } from "../components/Versao/Versao";
import CertificadosVencidos from "../components/Certificados/CertificadosVencidos";
import CertificadosVencendo from "../components/Certificados/CertificadosVencendo";
import { SistemasDesatualizadosLinha2, SistemasDesatualizadosLinha1 } from "../components/Versao/SistemaDesatualizados";
import { ProximasAtualizacoesLinha2, ProximasAtualizacoesLinha1 } from "../components/Versao/ProximasAtualizacoes";
import InformacaoBackup from "../components/Backup/InformacaoBackup";
import VersoesTransmitidas from "../components/Versao/VersoesTransmitidas";
import InfoLib from "../components/Licenca/InfoLib";
import Licencas from "../components/Licenca/Licencas";
import EventosLiberacao from "../components/Eventos/EventosLiberacao";
import ClientesInadimplentesConsolidado from "../components/Clientes/ClientesInadimplentesConsolidado";
import ClientesInadimplentes from "../components/Clientes/ClientesInadimplentes";
import QuebrasSequenciaNFe from "../components/NFe/QuebrasSequenciaNFe";
import Discos from "../components/Discos/Discos";
import PendenciasNFe from "../components/NFe/PendenciasNFe";
import AddVersao from "../components/Versao/AddVersao";
import TesteTabela from "../components/Tabela/TesteTabela";
import Logout from "../components/Login/Logout";
import ChavesDuplicadasNFe from "../components/NFe/ChavesDuplicadasNFe";
import MovimentosMultiplasNFe from "../components/NFe/MovimentosMultiplasNFe";
import ContasAnalitico from "../components/Contas/ContasAnalitico";
import AtualizacaoAutomatica from "../components/Atualizacao/AtualizacaoAutomatica";
import AtualizadoresAtivos from "../components/Atualizacao/AtualizadoresAtivos";
import Notificacao from "../components/Notificacao/Notificacao";
import AddNotificacao from "../components/Notificacao/AddNotificacao";
import LiberarClienteInadimplente from "../components/Clientes/LiberarClienteInadimplente";
import Usuarios from "../components/Seguranca/Usuarios";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AddUsuario from "../components/Seguranca/AddUsuario";
import Configuracoes from "../components/Seguranca/Configuracoes";
import AddConfiguracoes from "../components/Seguranca/AddConfiguracoes";
import InconsistenciasPISCOFINS from "../components/Produtos/InconsistenciasPISCOFINS";
import LiberacaoLicenca from "../components/Licenca/LiberacaoLicenca";
import LiberacaoLicencaVencendo from "../components/Licenca/LiberacaoLicencaVencendo";
import SemAcesso from "../components/Login/SemAcesso";
import AddAtualizacaoAutomatica from "../components/Atualizacao/AddAtualizacaoAutomatica";
import CNPJInstalados from "../components/Estatistica/CNPJInstalados";
import { GraphicEqRounded } from "@material-ui/icons";
import Instalador from "../components/Instalador/Instalador";
import ExecucaoScripts from "../components/Atualizacao/ExecucaoScripts";
import AddExecucaoScript from "../components/Atualizacao/AddExecucaoScript";
import InstaladorSmartCompany from "../components/Instalador/InstaladorSmartCompany";
import SecurityIcon from '@material-ui/icons/Security';
import Loop from '@material-ui/icons/Loop';
import { IGruposUsuario } from "../utils/SecTypes";
import GruposEconomicos from "../components/Licenca/GruposEconomicos";
import IA from "./ia.png"
import AgenteSuporte from "../components/ia/AgenteSuporte";
import Pixianne from "../components/ia/Pixianne";
import CertificadoSite from "../components/CertiticadoSite/CertificadoSite";
import AddCertificadoSite from "../components/CertiticadoSite/AddCertificadoSite";

export interface IRouteInfo {
  path?: string;
  name: string;
  rtlName?: string;
  icon?: React.ReactNode;
  component?: React.ReactNode;
  layout?: string;
  grupo?: string;
  gruposPermitidos?: IGruposUsuario[];
  items?: IRouteInfo[];
}

export const RoutesNonMenu: IRouteInfo[] = [
  {
    path: "/login",
    name: "Login",
    rtlName: "",
    icon: Dashboard,
    component: Login,
    layout: "",
    grupo: "",
  },
  {
    path: "/addversao",
    name: "AddVersao",
    rtlName: "",
    icon: Dashboard,
    component: AddVersao,
    layout: "",
    grupo: "",
  },
  {
    path: "/getinfolib",
    name: "Liberar Licença LIB",
    rtlName: "",
    icon: LabelIcon,
    component: InfoLib,
    layout: "",
    grupo: "",
    gruposPermitidos: ["criarlicenca1", "criarlicenca2", "criarlicenca3", "noc"],
  },
  {
    path: "/logout",
    name: "Logout",
    rtlName: "",
    icon: Dashboard,
    component: Logout,
    layout: "",
    grupo: "",
  },
  {
    path: "/liberarclienteinadimplente/:tipo/:codcf",
    name: "Liberar Cliente Inadimplente",
    rtlName: "",
    icon: Dashboard,
    component: LiberarClienteInadimplente,
    layout: "",
    grupo: "",
  },
  {
    path: "/addusuario/:tipo/:id",
    name: "Adicionar Usuário",
    rtlName: "",
    icon: Dashboard,
    component: AddUsuario,
    layout: "",
    grupo: "",
    gruposPermitidos: ["seguranca", "noc"],
  },
  {
    path: "/addusuario/:tipo",
    name: "Adicionar Usuário",
    rtlName: "",
    icon: Dashboard,
    component: AddUsuario,
    layout: "",
    grupo: "",
    gruposPermitidos: ["seguranca", "noc"],
  },
  {
    path: "/addconfiguracoes/:tipo/:id",
    name: "Adicionar Parâmetros",
    rtlName: "",
    icon: Dashboard,
    component: AddConfiguracoes,
    layout: "",
    grupo: "",
    gruposPermitidos: ["seguranca", "noc"],
  },
  {
    path: "/addconfiguracoes/:tipo",
    name: "Adicionar Parâmetros",
    rtlName: "",
    icon: Dashboard,
    component: AddConfiguracoes,
    layout: "",
    grupo: "",
    gruposPermitidos: ["noc"],
  },
  {
    path: "/addatualizacaoautomatica/:tipo/:id",
    name: "Adicionar Atualização Automática",
    rtlName: "",
    icon: Dashboard,
    component: AddAtualizacaoAutomatica,
    layout: "",
    grupo: "",
    gruposPermitidos: ["atualizacao", "noc"],
  },
  {
    path: "/addatualizacaoautomatica/:tipo",
    name: "Adicionar Atualização Automática",
    rtlName: "",
    icon: Dashboard,
    component: AddAtualizacaoAutomatica,
    layout: "",
    grupo: "",
    gruposPermitidos: ["atualizacao", "noc"],
  },
  {
    path: "/addexecucaoscript/:tipo/:id",
    name: "Adicionar Execução de Script",
    rtlName: "",
    icon: Dashboard,
    component: AddExecucaoScript,
    layout: "",
    grupo: "",
    gruposPermitidos: ["noc"],
  },
  {
    path: "/addexecucaoscript/:tipo",
    name: "Adicionar Execução de Script",
    rtlName: "",
    icon: Dashboard,
    component: AddExecucaoScript,
    layout: "",
    grupo: "",
    gruposPermitidos: ["noc"],
  },
  {
    path: "/addnotificacao/:tipo/:id",
    name: "Adicionar Notificação",
    rtlName: "",
    icon: Dashboard,
    component: AddNotificacao,
    layout: "",
    grupo: "",
  },  
  {
    path: "/addnotificacao/:tipo",
    name: "Adicionar Notificação",
    rtlName: "",
    icon: Dashboard,
    component: AddNotificacao,
    layout: "",
    grupo: "",
  },
  {
    path: "/certificado-site/:tipo",
    name: "Adicionar Certificado de Site",
    rtlName: "",
    icon: Dashboard,
    component: AddCertificadoSite,
    layout: "",
    grupo: "",
    gruposPermitidos: ["noc"],
  },
  {
    path: "/semacesso",
    name: "Sem Acesso",
    rtlName: "",
    icon: Dashboard,
    component: SemAcesso,
    layout: "",
    grupo: "",
  },

]

const Routes: IRouteInfo[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    grupo: "",
  },
  {
    path: "/notificacao",
    name: "Notificações",
    rtlName: "",
    icon: Dashboard,
    component: Notificacao,
    layout: "/admin",
    grupo: "",
  },
  // {
  //   path: "/testetabela",
  //   name: "Tabela",
  //   rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: TesteTabela,
  //   layout: "/admin",
  //   grupo: "",
  // },
  {
    name: "Segurança",
    icon: SupervisedUserCircleIcon,
    items: [
      {
        path: "/login",
        name: "Entrar",
        rtlName: "",
        icon: LabelIcon,
        component: Login,
        layout: "/admin",
      },
      {
        path: "/logout",
        name: "Sair",
        rtlName: "",
        icon: LabelIcon,
        component: Logout,
        layout: "/admin",
      },
      {
        path: "/usuarios",
        name: "Usuários",
        rtlName: "",
        icon: LabelIcon,
        component: Usuarios,
        layout: "/admin",
        gruposPermitidos: ["seguranca", "noc"],
      },
      {
        path: "/configuracoes",
        name: "Configurações",
        rtlName: "",
        icon: LabelIcon,
        component: Configuracoes,
        layout: "/admin",
        gruposPermitidos: ["seguranca", "noc"],
      },
    ],
  },    
  {
    name: "Instaladores",
    icon: EventNoteIcon,
    items: [
      {
        path: "/instalador",
        name: "Instalador NOC",
        rtlName: "",
        icon: LabelIcon,
        component: Instalador,
        layout: "/admin",
        gruposPermitidos: ["instalacao", "noc"],
      },
      {
        path: "/smartcompany-instalador",
        name: "Instalador SmartCompany",
        rtlName: "",
        icon: LabelIcon,
        component: InstaladorSmartCompany,
        layout: "/admin",
        gruposPermitidos: ["instalacao", "noc"],
      },
    ]
  },
  {
    name: "Cadastro",
    icon: EventNoteIcon,
    items: [
      {
        path: "/empresas",
        name: "Empresas",
        rtlName: "",
        icon: LabelIcon,
        component: Empresas,
        layout: "/admin",
        gruposPermitidos: [],
      },
      {
        path: "/lojas",
        name: "Lojas",
        rtlName: "",
        icon: LabelIcon,
        component: Lojas,
        layout: "/admin",
        gruposPermitidos: [],
      },
                
    ]
  },
  {
    name: "Versionamento",
    icon: Loop,
    items: [
      {
        path: "/versao1",
        name: "Versões Linha 1",
        icon: LabelIcon,
        component: VersaoLinha1,
        layout: "/admin",
        gruposPermitidos: ["noc"],
      },
      {
        path: "/versao2",
        name: "Versões Linha 2",
        icon: LabelIcon,
        component: VersaoLinha2,
        layout: "/admin",
        gruposPermitidos: ["noc"],
      },
      {
        path: "/versoestransmitidas",
        name: "Versões Transmitidas",
        rtlName: "",
        icon: LabelIcon,
        component: VersoesTransmitidas,
        layout: "/admin",
        gruposPermitidos: [],
      },
      {
        path: "/proximasatualizacoes1",
        name: "Próximas Atualizações - Linha 1",
        rtlName: "",
        icon: LabelIcon,
        component: ProximasAtualizacoesLinha1,
        layout: "/admin",
        gruposPermitidos: [],
      },
      {
        path: "/proximasatualizacoes2",
        name: "Próximas Atualizações - Linha 2",
        rtlName: "",
        icon: LabelIcon,
        component: ProximasAtualizacoesLinha2,
        layout: "/admin",
        gruposPermitidos: [],
      },
      {
        path: "/sistemasdesatualizados1",
        name: "Sistemas Desatualizados - Linha 1",
        rtlName: "",
        icon: LabelIcon,
        component: SistemasDesatualizadosLinha1,
        layout: "/admin",
        gruposPermitidos: [],
      },
      {
        path: "/sistemasdesatualizados2",
        name: "Sistemas Desatualizados - Linha 2",
        rtlName: "",
        icon: LabelIcon,
        component: SistemasDesatualizadosLinha2,
        layout: "/admin",
        gruposPermitidos: [],
      },
    ],
  },
  {
    name: "Backup's e Atualizações",
    icon: BackupIcon,
    items: [
      {
        path: "/informacaobackup",
        name: "Informações de Backup's",
        rtlName: "",
        icon: LabelIcon,
        component: InformacaoBackup,
        layout: "/admin",
        gruposPermitidos: ["noc"],
      },
      {
        path: "/atualizadoresativos",
        name: "Atualizadores Ativos",
        rtlName: "",
        icon: LabelIcon,
        component: AtualizadoresAtivos,
        layout: "/admin",
        gruposPermitidos: ["noc"],
      },
      {
        path: "/atualizacaoautomatica",
        name: "Atualizações Automáticas",
        rtlName: "",
        icon: LabelIcon,
        component: AtualizacaoAutomatica,
        layout: "/admin",
        gruposPermitidos: ["noc"],
      },
      {
        path: "/execucaoscripts",
        name: "Execução de Scripts",
        rtlName: "",
        icon: LabelIcon,
        component: ExecucaoScripts,
        layout: "/admin",
        gruposPermitidos: ["noc"],
      },
    ],
  },
  /*
  {
    name: "Produtos",
    icon: FeaturedPlayListIcon,
    items: [
      {
        path: "/inconsistenciapiscofins",
        name: "Inconsistências PIS/COFINS",
        rtlName: "",
        icon: LabelIcon,
        component: InconsistenciasPISCOFINS,
        layout: "/admin",
      },
    ],
  },
  */
  {
    name: "Estatísticas",
    icon: GraphicEqRounded,
    items: [
      {
        path: "/utilizacaodisco",
        name: "Utilização dos Discos",
        rtlName: "",
        icon: LabelIcon,
        component: Discos,
        layout: "/admin",
      },
      {
        path: "/estatisticas_cnpjinstalados",
        name: "CNPJ Instalados",
        rtlName: "",
        icon: LabelIcon,
        component: CNPJInstalados,
        layout: "/admin",
      },
      {
        path: "/certificados-sites",
        name: "Certificados de Sites",
        rtlName: "",
        icon: LabelIcon,
        component: CertificadoSite,
        layout: "/admin",
      },
    ],
  },
  {
    name: "NF-e",
    icon: Person,
    items: [
      {
        path: "/certificadosvencendo",
        name: "Certificados Vencendo",
        rtlName: "",
        icon: LabelIcon,
        component: CertificadosVencendo,
        layout: "/admin",
        gruposPermitidos: ["suporte", "noc"],
      },
      {
        path: "/certificadosvencidos",
        name: "Certificados Vencidos",
        rtlName: "",
        icon: LabelIcon,
        component: CertificadosVencidos,
        layout: "/admin",
        gruposPermitidos: ["suporte", "noc"],
      },
      {
        path: "/pendenciasnfe",
        name: "Pendências de NF-e",
        rtlName: "",
        icon: LabelIcon,
        component: PendenciasNFe,
        layout: "/admin",
        gruposPermitidos: ["suporte", "noc"],
      },
      {
        path: "/quebrassequencianfe",
        name: "Quebras de Sequências",
        rtlName: "",
        icon: LabelIcon,
        component: QuebrasSequenciaNFe,
        layout: "/admin",
        gruposPermitidos: ["suporte", "noc"],
      },
      {
        path: "/mov1",
        name: "NF-e com Múlt. Movimentos",
        rtlName: "",
        icon: LabelIcon,
        component: ChavesDuplicadasNFe,
        layout: "/admin",
        gruposPermitidos: ["suporte", "noc"],
      },
      {
        path: "/mov2",
        name: "Movimentos com Múlt. NF-e",
        rtlName: "",
        icon: LabelIcon,
        component: MovimentosMultiplasNFe,
        layout: "/admin",
        gruposPermitidos: ["suporte", "noc"],
      },

    ],
  },
  {
    name: "Financeiro",
    icon: MonetizationOnIcon,
    items: [
      {
        path: "/contasanalitico",
        name: "Contas - Analítico",
        rtlName: "",
        icon: LabelIcon,
        component: ContasAnalitico,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "noc"],
      },
      {
        path: "/clientesconsolidado",
        name: "Clientes Consolidado",
        rtlName: "",
        icon: LabelIcon,
        component: Clientes,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "noc"],
      },
      {
        path: "/clientesinadimplentes",
        name: "Clientes inadimplentes",
        rtlName: "",
        icon: LabelIcon,
        component: ClientesInadimplentes,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "noc"],
      },
      {
        path: "/clientesinadimplentesconsolidado",
        name: "Clientes inadimplentes - Consolidado",
        rtlName: "",
        icon: LabelIcon,
        component: ClientesInadimplentesConsolidado,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "noc"],
      },
      {
        path: "/gruposeconomicos",
        name: "Grupos Econômicos",
        rtlName: "",
        icon: LabelIcon,
        component: GruposEconomicos,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "licenca", "noc"],
      },
    ],
  },
  {
    name: "Licenciamento",
    icon: SecurityIcon,
    items: [
      {
        path: "/eventosliberacao",
        name: "Eventos Liberação",
        rtlName: "",
        icon: LabelIcon,
        component: EventosLiberacao,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "noc"],
      },
      {
        path: "/licencas",
        name: "Licenças",
        rtlName: "",
        icon: LabelIcon,
        component: Licencas,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "licenca", "noc"],
      },
      // {
      //   path: "/getinfolib",
      //   name: "Liberar Licença LIB",
      //   rtlName: "",
      //   icon: LabelIcon,
      //   component: InfoLib,
      //   layout: "/admin",
      // },
      {
        path: "/liberacaolicenca",
        name: "Liberar Licença LIB",
        rtlName: "",
        icon: LabelIcon,
        component: LiberacaoLicenca,
        layout: "/admin",
        gruposPermitidos: ["financeiro", "licenca", "noc"],
      },
      {
        path: "/liberacaolicencavencendo",
        name: "Licença LIB Vencendo",
        rtlName: "",
        icon: LabelIcon,
        component: LiberacaoLicencaVencendo,
        layout: "/admin",
        gruposPermitidos: [],
      },
      


    ],
  },
  {
    name: "IA Center",
    icon: () => <img src={IA} width={24} height={24} style={{marginRight: 15, color: "white"}} />,
    items: [
      {
        path: "/agentesuporte",
        name: "Agente Suporte",
        rtlName: "",
        icon: LabelIcon,
        component: AgenteSuporte,
        layout: "/admin",
        gruposPermitidos: [],
      },
      {
        path: "/pixianne",
        name: "Pixianne",
        rtlName: "",
        icon: LabelIcon,
        component: Pixianne,
        layout: "/admin",
        gruposPermitidos: [],
      },
    ],
  },
  

  

  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  /*{
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin",
  }*/
];

export default Routes;
